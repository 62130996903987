import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  BookedSchedule,
  Booking,
  BookingSourceActor,
  BookingSourcePlatform,
  ContactDetails,
  CustomFormField,
  ParticipantChoices,
  SelectedPaymentOption,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormNestedSlot } from '../../../types/formSelectedSlots';
import { mapFormNestedSlotToServerSlot } from '../../../utils/mappers/ambassador-bookings-v2.mapper';
import { mapDynamicParticipantsNumber } from '../../../utils/mappers/form-submission.mapper';
import { FormService } from '../../../utils/mappers/serviceMapper/service.mapper';
import { SlotService } from '../../../utils/state/types';
import Experiments from '@wix/wix-experiments';
import { ExperimentsConsts } from '../../../consts/experiments';

export const createParticipantNotificationObject = ({
  experiments,
  sendSmsReminder,
}: {
  experiments?: Experiments;
  sendSmsReminder?: boolean;
}) => {
  if (experiments?.enabled(ExperimentsConsts.FormUseAutomationsForSMS)) {
    return {
      participantNotification: {
        notifyParticipants: true,
        metadata: { channels: 'EMAIL,SMS' },
      },
      sendSmsReminder: true,
    };
  }

  return {
    participantNotification: {
      notifyParticipants: true,
    },
    sendSmsReminder,
  };
};

export const createBookingEntity = ({
  slotService,
  numberOfParticipants,
  additionalFields,
  contactDetails,
}: {
  slotService: SlotService;
  numberOfParticipants: number | undefined;
  additionalFields: CustomFormField[];
  contactDetails: ContactDetails;
}): Booking => {
  const selectedMembership = slotService.memberships?.eligibleMemberships?.find(
    (membership) => membership?.id === slotService.selectedPaymentOption.id,
  );
  const { participantsChoices, totalParticipants } =
    mapDynamicParticipantsNumber(
      slotService.dynamicPriceInfo?.selectedVariants,
    );
  const isCourse = slotService.service.type === ServiceType.COURSE;

  return {
    v2Availability: slotService?.nestedSlot?.v2Availability || false,
    ...getParticipantsField(
      participantsChoices,
      totalParticipants,
      numberOfParticipants,
    ),
    bookingSource: {
      actor: BookingSourceActor.CUSTOMER,
      platform: BookingSourcePlatform.WEB,
    },
    contactDetails,
    additionalFields,
    selectedPaymentOption: (selectedMembership
      ? SelectedPaymentOption.MEMBERSHIP
      : slotService.selectedPaymentType) as SelectedPaymentOption,
    bookedEntity: {
      ...(isCourse
        ? {
            schedule: mapBookedSchedule({
              service: slotService.service,
              slot: slotService.nestedSlot,
            }),
          }
        : {
            slot: mapFormNestedSlotToServerSlot(slotService.nestedSlot),
          }),
    },
  };
};

const mapBookedSchedule = ({
  service,
  slot,
}: {
  service: FormService;
  slot: FormNestedSlot;
}): BookedSchedule => {
  return {
    serviceId: service.id,
    scheduleId: service.scheduleId,
    timezone: slot.timezone,
  };
};

const getParticipantsField = (
  participantsChoices?: ParticipantChoices,
  totalParticipants?: number,
  numberOfParticipants?: number,
) => {
  if (participantsChoices) {
    return { participantsChoices };
  }
  if (totalParticipants) {
    return { totalParticipants };
  }
  return { numberOfParticipants: numberOfParticipants || 1 };
};
