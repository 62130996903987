import { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import { SlotService } from '../../../utils/state/types';
import { createMultiServiceBooking } from '@wix/ambassador-bookings-v2-booking/http';
import {
  Booking,
  ContactDetails,
  CreateMultiServiceBookingRequest,
  CustomFormField,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import {
  createBookingEntity,
  createParticipantNotificationObject,
} from './utils';

export const sendCreateMultiServiceBookingsRequest = async (
  httpClient: IHttpClient,
  slotServices: SlotService[],
  contactDetails: ContactDetails,
  additionalFields: CustomFormField[],
  numberOfParticipants: number | undefined,
  sendSmsReminder: boolean | undefined,
  experiments?: Experiments,
) => {
  const bookings: Booking[] = mapToArray<SlotService>(slotServices).map(
    (slotService) => {
      return createBookingEntity({
        slotService,
        numberOfParticipants,
        additionalFields,
        contactDetails,
      });
    },
  );

  const createMultiServiceBookingsRequest: CreateMultiServiceBookingRequest = {
    bookings,
    ...createParticipantNotificationObject({ sendSmsReminder, experiments }),
  };
  const createGroupBookingsResult = await httpClient.request(
    createMultiServiceBooking(createMultiServiceBookingsRequest),
  );

  const result: { [key: string]: string } = {};
  if (
    createGroupBookingsResult.data.multiServiceBooking &&
    createGroupBookingsResult.data.multiServiceBooking.bookings &&
    createGroupBookingsResult.data.multiServiceBooking.bookings.length
  ) {
    slotServices.forEach((slotService: SlotService, index: number) => {
      result[getServiceSlotIdentifier(slotService.nestedSlot)] =
        createGroupBookingsResult.data.multiServiceBooking!.bookings![
          index
        ].bookingId!;
    });
  }

  return result;
};
