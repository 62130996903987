import {
  Booking,
  ContactDetails,
  CustomFormField,
  BulkCreateBookingRequest,
  BulkCreateBookingResponse,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import { bulkCreateBooking } from '@wix/ambassador-bookings-v2-booking/http';
import {
  createBookingEntity,
  createParticipantNotificationObject,
} from './utils';
import { SlotService } from '../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../utils';
import { PlatformServerError } from '../../../../__tests__/mocks/ambassador/mockPlatformError';

export const sendCreateBookingsRequest = async (
  httpClient: IHttpClient,
  slotServices: SlotService[],
  contactDetails: ContactDetails,
  additionalFields: CustomFormField[],
  numberOfParticipants: number | undefined,
  sendSmsReminder: boolean | undefined,
  experiments?: Experiments,
) => {
  const singleRequests = slotServices.map((slotService: SlotService) => {
    const booking: Booking = createBookingEntity({
      slotService,
      numberOfParticipants,
      additionalFields,
      contactDetails,
    });

    return {
      booking,
      ...createParticipantNotificationObject({ experiments, sendSmsReminder }),
    };
  });

  const bulkCreateBookingRequest: BulkCreateBookingRequest = {
    createBookingsInfo: singleRequests,
  };
  const requestRes = await httpClient.request(
    bulkCreateBooking(bulkCreateBookingRequest),
  );

  if (requestRes?.data?.bulkActionMetadata?.totalFailures) {
    throwPlatformServerError(requestRes.data);
  }

  const result: { [key: string]: string } = {};
  if (requestRes.data.results?.length) {
    slotServices.forEach((slotService: SlotService, index: number) => {
      result[getServiceSlotIdentifier(slotService.nestedSlot)] =
        requestRes.data.results![index]?.itemMetadata?.id!;
    });
  }

  return result;
};

const throwPlatformServerError = (requestRes: BulkCreateBookingResponse) => {
  const error = requestRes?.results?.find(
    (result) => !result.itemMetadata?.success,
  )?.itemMetadata?.error;
  const platformServerError: PlatformServerError = {
    details: {
      applicationError: {
        code: error?.code,
      },
    },
    message: error?.description,
  };

  throw {
    response: platformServerError,
  };
};
