import { Booking } from '@wix/ambassador-bookings-server/types';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { BusinessInfo } from '@wix/bookings-uou-types';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import { PricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { ILinkType } from '@wix/yoshi-flow-editor';

export enum Referrals {
  BOOKINGS_FORM_WIDGET_WITH_CART = 'bookings_form_widget_with_cart',
}

export enum BookingsQueryParams {
  REFERRAL = 'referral',
  STAFF = 'staff',
  LOCATION = 'location',
  BOOKING_ID = 'bookingId',
  SERVICE_ID = 'service',
  AVAILABILITY_SLOT = 'slot',
  TIMEZONE = 'timezone',
  DYNAMIC_PRICING = 'dynamic-pricing',
  FILLED_FIELDS = 'filledFields',
  PUBLIC_DATA_OVERRIDES = 'publicDataOverrides',
  ECOM = 'ecom',
  CONTINUE_SHOPPING_URL = 'continueShoppingUrl',
  DATE = 'date',
  RESOURCE = 'resource',
  SELECTIONS = 'selections',
  VIEW_MODE = 'viewMode',
}

export interface IFlowApiAdapter {
  isCartEnabled(businessInfo: BusinessInfo): Promise<boolean>;
  getCsrfToken(): string;
  getUrlQueryParamValue(key: BookingsQueryParams): string;
  getServerBaseUrl(): string;
  getSiteRevision(): string;
  isEditorMode(): boolean;
  isPreviewMode(): boolean;
  isPricingPlanInstalled(): Promise<boolean>;
  isSiteMode(): boolean;
  isSSR(): boolean;
  isTemplateMode(): any;
  onUserLogin(callback: any): void;
  promptUserLogin(): Promise<IUser>;
  getInstance(): string;
  getMetaSiteId(): string;
  isOwner(): boolean;
  getPricingPlansApi(): Promise<PricingPlansPublicAPI>;
  navigateToHome(): Promise<void>;
  navigateToContinueShopping(linkInfo?: ILinkType | null): Promise<void>;
  navigateToPricingPlan({
    redirectTo,
    planIds,
    maxStartDate,
    queryParams,
    title,
    subtitle,
  }: {
    redirectTo: {
      sectionId: string;
      relativePath: string;
    };
    planIds?: string[];
    maxStartDate?: string;
    queryParams?: {};
    title?: string;
    subtitle?: string;
  }): Promise<void>;
  getUrlQueryParams(): {
    [key: string]: string;
  };
  getFromSessionStorage(key: string): string;
  setToSessionStorage(key: string, value: string): void;
  setRobotsNoIndex: () => void;
  getAppDefId(): string;
  getInstanceId(): string;
  navigateAfterAddToCart(viewCart?: boolean): Promise<void>;
  navigateToEcomCart(): Promise<void>;
  navigateToEcomThankYouPage({
    orderId,
    continueShoppingUrl,
  }: {
    orderId: string;
    continueShoppingUrl?: string;
  }): Promise<void>;
  navigateToBookingsCheckout(
    bookedEntity: Booking,
    referral?: string,
    timezone?: string,
  ): Promise<void>;
  openEcomCheckoutPreviewModal(): Promise<any>;
  reloadCart(): Promise<void>;
  openUoUPremiumModal(
    serviceType: ServiceType,
    referralInfo: any,
    isDayful?: boolean,
  ): Promise<any>;
  navigateToEcomCheckoutPage({
    checkoutId,
    continueShoppingUrl,
  }: {
    checkoutId: string;
    continueShoppingUrl?: string;
  }): Promise<void>;
  getBookOnlineFullUrl(): Promise<string>;
  openEcomCheckoutTemplateModal(): Promise<any>;
  getSiteLanguage(): string;
  getSiteLocale(): string;
  getDateRegionalSettingsLocale(): string;
}
