import React from 'react';
import {
  FormView,
  FormViewField,
  MultiLineAddressOptions,
  Submission,
  SubmissionResponse,
  SubmissionValue,
  FormRef,
} from '@wix/forms-ui/types';
import { FieldType } from '@wix/ambassador-forms-v2-form/types';
import {
  CustomFormField,
  ParticipantChoices,
  ContactDetails,
} from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { BookingsKeyMapping } from './service-form-field.mapper';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { BusinessInfo } from '../../types/types';
import { SelectedVariants } from '@wix/bookings-uou-types';
import { isDynamicVariant } from './dynamic-price.mapper';

export type OnSubmit = (submission: SubmissionResponse) => void;

export const enum BookingRequestKeyMappings {
  ADDRESS = 'address',
  CONTACT_ID = 'contactId',
  COUNTRY_CODE = 'countryCode',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  FULL_ADDRESS = 'fullAddress',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  TIMEZONE = 'timeZone',
  NO_OF_PARTICIPANTS = 'noOfParticipants',
  SMS_REMINDER = 'smsReminder',
}

export function getFieldIdByBookingKeyMap(formSchema: FormView): {
  [key: string]: string | undefined;
} {
  return (
    formSchema?.fields?.reduce<{
      [key: string]: string | undefined;
    }>((keyMap, field) => {
      const bookingsKeyMapping: BookingRequestKeyMappings =
        field?.renderInfo?.metadata?.bookingsKeyMapping?.key;
      const id = field.externalId;
      return {
        ...keyMap,
        [bookingsKeyMapping]: id,
      };
    }, {}) ?? {}
  );
}

function getPhoneFieldValue(member: Member, formSchema: FormView) {
  const phoneField = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.PHONE,
  );
  const phoneNumber = member.contact?.phones?.[0];
  const isPhoneWithCountryCodeField =
    phoneField?.renderInfo?.type === FieldType.PHONE_COUNTRY_CODE;
  if (!isPhoneWithCountryCodeField) {
    return phoneNumber;
  }
  return undefined;
}

function getMemberDetailsByBookingKeyMap(
  member: Member,
  formSchema: FormView,
): {
  [key: string]: any;
} {
  const memberAddress = member?.contact?.addresses?.[0];

  const mappedAddress = memberAddress
    ? {
        state: memberAddress.subdivision,
        city: memberAddress.city,
        streetLine1: memberAddress.addressLine,
        streetLine2: memberAddress.addressLine2,
      }
    : undefined;

  const isLastNameFieldEnabled = member.contact?.lastName
    ? !!getFieldFromSchema(formSchema, BookingRequestKeyMappings.LAST_NAME)
    : true;

  return {
    [BookingRequestKeyMappings.EMAIL]:
      member.contact?.emails?.[0] || member.loginEmail,
    [BookingRequestKeyMappings.PHONE]: getPhoneFieldValue(member, formSchema),
    [BookingRequestKeyMappings.FIRST_NAME]: `${
      member.contact?.firstName || ''
    }${isLastNameFieldEnabled ? '' : ` ${member.contact?.lastName}`}`.trim(),
    [BookingRequestKeyMappings.LAST_NAME]: member.contact?.lastName,
    [BookingRequestKeyMappings.FULL_ADDRESS]: mappedAddress,
  };
}

type DisplayProperties = {
  [externalId: string]: {
    value?: SubmissionValue;
    disabled?: boolean;
  };
};

export function mapMemberDetailsToDisplayPropertiesValues(
  bookingKeys: BookingRequestKeyMappings[],
  formSchema: FormView,
  member: Member,
  overrideDefaultFieldsValues = false,
) {
  const bookingsKeyToFieldId = getFieldIdByBookingKeyMap(formSchema);
  const bookingsKeyToMemberDetails = getMemberDetailsByBookingKeyMap(
    member,
    formSchema,
  );
  return bookingKeys.reduce<DisplayProperties>(
    (displayProperties: DisplayProperties, bookingKey) => {
      const fieldId = bookingsKeyToFieldId[bookingKey];
      const memberDetail = bookingsKeyToMemberDetails[bookingKey] || '';
      const isPhoneWithCountryCodeField =
        getFieldFromSchema(formSchema, bookingKey)?.renderInfo?.type ===
        FieldType.PHONE_COUNTRY_CODE;
      const isEmailField = bookingKey === BookingRequestKeyMappings.EMAIL;
      const isMemberEmailExists =
        (!!member?.contact?.emails && member?.contact?.emails?.length > 0) ||
        !!member?.loginEmail;
      const shouldOverrideDefaultValue =
        overrideDefaultFieldsValues ||
        isEmailField ||
        !fieldHasDefaultValue(formSchema, bookingKey);
      const shouldApplyMemberDetail =
        fieldId && shouldOverrideDefaultValue && !isPhoneWithCountryCodeField;
      return {
        ...displayProperties,
        ...(shouldApplyMemberDetail
          ? {
              [fieldId!]: {
                value: memberDetail,
                ...(isEmailField ? { readonly: isMemberEmailExists } : {}),
              },
            }
          : {}),
      };
    },
    {},
  );
}

function getSubmissionValue(
  submission: {
    [k: string]: SubmissionValue;
  },
  field: FormViewField,
) {
  if (
    typeof submission[field.externalId!] === 'boolean' &&
    !isSmsReminderField(field)
  ) {
    return submission[field.externalId!]!.toString();
  }
  return submission[field.externalId!];
}

export function mapFormSubmission({
  submission,
  formSchema,
  businessInfo,
  collapseFormValues,
  timezone,
}: {
  submission: Submission;
  formSchema: FormView;
  businessInfo: BusinessInfo;
  collapseFormValues?: ContactDetails;
  timezone?: string;
}): {
  contactDetails: ContactDetails;
  additionalFields: CustomFormField[];
  sendSmsReminder: boolean;
  numberOfParticipants: number;
} {
  const additionalFields: CustomFormField[] = [];
  const contactDetails: ContactDetails = {};
  let sendSmsReminder = false;
  let numberOfParticipants = 1;
  formSchema!.fields!.forEach((field) => {
    const value = getSubmissionValue(submission, field);
    if (value) {
      if (isNoOfParticipants(field)) {
        numberOfParticipants = Number(value);
      } else if (isSmsReminderField(field)) {
        sendSmsReminder = Boolean(value);
      } else if (isContactInfoField(field)) {
        appendContactInfoField(contactDetails, businessInfo, field, value);
      } else {
        appendAdditionalField(additionalFields, field, value);
      }
    } else if (!isSpecialField(field)) {
      appendAdditionalField(additionalFields, field, '');
    }
  });

  if (collapseFormValues) {
    appendContactInfoFromSubmission(
      contactDetails,
      collapseFormValues,
      businessInfo,
    );
  }

  if (timezone) {
    contactDetails.timeZone = timezone;
  }

  if (!contactDetails.firstName?.trim() && !contactDetails.lastName?.trim()) {
    contactDetails.firstName = contactDetails.email;
  }

  return {
    contactDetails,
    additionalFields,
    sendSmsReminder,
    numberOfParticipants,
  };
}

export function mapDynamicParticipantsNumber(
  selectedVariants?: SelectedVariants[],
): {
  participantsChoices?: ParticipantChoices;
  totalParticipants?: number;
} {
  let participantsChoices: ParticipantChoices | undefined;
  let totalParticipants: number | undefined;
  if (selectedVariants) {
    const isCustomBased = isDynamicVariant(selectedVariants[0]);
    if (isCustomBased) {
      participantsChoices = {
        serviceChoices: selectedVariants,
      };
    } else {
      totalParticipants = selectedVariants?.[0]?.numberOfParticipants || 0;
    }
  }
  return {
    totalParticipants,
    participantsChoices,
  };
}

export enum RateLabels {
  GENERAL = 'general',
}

function isContactInfoField(field: FormViewField) {
  return !!field?.renderInfo?.metadata?.bookingsKeyMapping;
}

function isSpecialField(field: FormViewField) {
  return (
    isContactInfoField(field) ||
    isSmsReminderField(field) ||
    isNoOfParticipants(field)
  );
}

function appendContactInfoField(
  contactDetails: ContactDetails,
  businessInfo: BusinessInfo,
  field: FormViewField,
  value: any,
) {
  const bookingsKeyMapping: BookingRequestKeyMappings =
    field?.renderInfo?.metadata?.bookingsKeyMapping?.key;
  const formattedValue =
    mapFormValueToContactInfoProperty?.[bookingsKeyMapping]?.(
      value,
      businessInfo,
    ) ?? value;
  if (formattedValue) {
    contactDetails[bookingsKeyMapping as keyof ContactDetails] = formattedValue;
  }
}

function appendContactInfoFromSubmission(
  contactDetails: ContactDetails,
  collapseFormValues: ContactDetails,
  businessInfo: BusinessInfo,
) {
  Object.keys(collapseFormValues).forEach((key: string) => {
    const field = { renderInfo: { metadata: { bookingsKeyMapping: { key } } } };
    appendContactInfoField(
      contactDetails,
      businessInfo,
      field,
      // @ts-expect-error
      collapseFormValues[key],
    );
  });
}

function appendAdditionalField(
  additionalFields: CustomFormField[],
  field: FormViewField,
  value: any,
) {
  additionalFields.push({
    id: field.externalId,
    value: String(value),
  });
}

function isNoOfParticipants(field: FormViewField) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return (
    bookingsKeyMapping?.key === BookingRequestKeyMappings.NO_OF_PARTICIPANTS
  );
}

function isSmsReminderField(field: FormViewField) {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return bookingsKeyMapping?.key === BookingRequestKeyMappings.SMS_REMINDER;
}

const filterUndefined = (value: any) => !!value;

export const trimPhoneValue = (phone: string, complex: boolean) => {
  const regex = complex ? /\D/g : /[^+\d]/g;
  return phone?.replace(regex, '');
};

export const mapFormValueToContactInfoProperty: {
  [key in BookingRequestKeyMappings]?: (
    value: any,
    businessInfo: BusinessInfo,
  ) => any;
} = {
  phone: (
    value: string | { countryCode: string; prefix: string; phone: string },
  ) => {
    if (typeof value === 'object') {
      const { prefix, phone } = value;
      return phone ? `${prefix}${trimPhoneValue(phone, true)}` : '';
    }
    return trimPhoneValue(value, false);
  },
  fullAddress: (
    value: { [key in keyof MultiLineAddressOptions]: string },
    businessInfo,
  ) => {
    const { state, city, streetLine1, streetLine2 } = value;
    const isInJapanese = businessInfo.language === 'ja';
    const formattedAddress = isInJapanese
      ? `${[state, city, streetLine1].filter(filterUndefined).join('')}${
          streetLine2 ? ` ${streetLine2}` : ''
        }`
      : [streetLine1, streetLine2, city, state]
          .filter(filterUndefined)
          .join(', ');

    return {
      subdivision: state,
      city,
      addressLine: streetLine1,
      addressLine2: streetLine2,
      formattedAddress,
    };
  },
};

export function getFieldFromSchema(
  formSchema: FormView,
  bookingKey: BookingRequestKeyMappings,
) {
  return formSchema.fields?.find(
    (field) =>
      field.renderInfo?.metadata?.bookingsKeyMapping?.key === bookingKey,
  );
}

export function disableField(
  formSchema: FormView,
  bookingKey: BookingRequestKeyMappings,
  formRef: React.MutableRefObject<FormRef | undefined>,
  disabled: boolean,
) {
  const fieldId = getFieldIdByBookingKeyMap(formSchema)[bookingKey];

  if (fieldId) {
    formRef!.current!.setFieldDisplayProperties({
      [fieldId]: {
        disabled,
      },
    });
  }
}

export function setFieldValue(
  formSchema: FormView,
  bookingKey: BookingRequestKeyMappings,
  formRef: React.MutableRefObject<FormRef | undefined>,
  value: any,
) {
  const fieldId = getFieldIdByBookingKeyMap(formSchema)[bookingKey];
  if (fieldId) {
    formRef!.current!.setFieldDisplayProperties({ [fieldId]: { value } });
  }
}

function fieldHasDefaultValue(
  formSchema: FormView,
  bookingKey: BookingRequestKeyMappings,
) {
  const defaultValue = getFieldFromSchema(formSchema, bookingKey)?.renderInfo
    ?.displayProperties?.defaultValue;
  return defaultValue !== undefined;
}
