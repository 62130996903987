import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { FormStatus } from '../../types/form-state';
import { FormState } from '../../utils/state/types';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  createFormContext,
  FormContext,
} from '../../utils/context/contextFactory';
import { createFormActions, FormControllerActions } from './Actions/actions';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { FormApi } from '../../api/FormApi';
import { mapConfigToState } from '../../utils/dummies/editor-behaviour';
import { createFormBiLogger } from '../../utils/bi/biLoggerFactory';
import { extractAppSection } from '../../api/PageAPIAdapter/URLAdapter/utils';
import { EditorViewStates } from '../../types/types';
import { withErrorHandler } from '@wix/bookings-viewer-error-handler';

const createController: CreateControllerFn = async ({
  flowAPI: flowApi,
}: ControllerParams) => {
  let formControllerActions: FormControllerActions;
  let rerender: (
    propsToUpdate: Partial<FormState | { actions: FormControllerActions }>,
  ) => void = () => {};

  let markStateAsInitialized: Function;

  const stateInitializedPromise = new Promise((res) => {
    markStateAsInitialized = res;
  });

  const updateViewState = (viewStateId: EditorViewStates) => {
    if (formControllerActions) {
      switch (viewStateId) {
        case EditorViewStates.FORM: {
          formControllerActions.onDialogClose();
          break;
        }
        case EditorViewStates.ADDED_TO_CART_MODAL: {
          formControllerActions.openCartDialogForEditorView();
          break;
        }
        default: {
          console.error('Error with updateWidgetViewState in Bookings Form');
        }
      }
    }
  };

  return {
    async pageReady() {
      const {
        controllerConfig,
        translations: { t },
        reportError,
        httpClient,
        experiments,
        errorHandler,
      } = flowApi;

      if (
        flowApi.experiments.enabled('specs.bookings.withErrorHandlerCheckout')
      ) {
        withErrorHandler({ httpClient, errorHandler });
      }

      extractAppSection(flowApi);

      const bookingsDataCapsule = await BookingsDataCapsule.getInstance(
        flowApi as any,
      );
      const wixSdkAdapter = createWixSdkAdapter(controllerConfig, experiments);
      const formApi = new FormApi({
        httpClient,
        flowApi,
        bookingsDataCapsule,
        wixSdkAdapter,
        reportError,
        experiments,
      });

      const shouldInitializeBiLogger = flowApi?.bi! && !wixSdkAdapter.isSSR();
      const biLogger = shouldInitializeBiLogger
        ? createFormBiLogger({
            viewerBiLogger: flowApi?.bi!,
            wixSdkAdapter,
          })
        : undefined;

      const user = controllerConfig.wixCodeApi.user.currentUser;
      const formContext: FormContext = createFormContext({
        t,
        flowApi,
        wixSdkAdapter,
        bookingsDataCapsule,
        formApi,
        biLogger,
        reportError,
        user,
      });

      const { render, actions, onStateChange } =
        await createControlledComponent<
          any,
          FormControllerActions,
          FormContext
        >({
          controllerConfig,
          initialState: {
            status: FormStatus.INITIALIZING,
          } as Partial<FormState>,
          actionsFactory: createFormActions,
          context: formContext,
          flowApi,
          formApi,
        });
      rerender = render;
      formControllerActions = actions;

      if (!wixSdkAdapter.isSSR()) {
        onStateChange((state) => {
          biLogger?.update(state);
        });
      }

      wixSdkAdapter.onUserLogin(actions.onLogin);

      onStateChange(() => {
        markStateAsInitialized();
      });
    },
    updateConfig($w, config) {
      const stateUpdate = mapConfigToState(config);
      rerender(stateUpdate);
    },
    getWidgetViewStateAfterRefresh: async (viewStateId: EditorViewStates) => {
      await stateInitializedPromise;

      updateViewState(viewStateId);
    },
    updateWidgetViewState: (viewStateId: EditorViewStates) => {
      updateViewState(viewStateId);
    },
  };
};

export default createController;
